import React from "react";
import Slider from "react-slick";
import { Container } from "react-grid-system";
import { Link, useRouteMatch } from "react-router-dom";
// Components
import Headline from "../../components/common/Headline";

const posts = [
  {
    title: "SMA - Smart Media Analysis",
    image: "/assets/images/blog/Portfolio-SMA.jpg",
    date: "Plataforma Web de análisis de contenidos para Redes Sociale",
    link: "/SMA",
    icon: "ti-light-bulb",
  },
  {
    title: "Portafolio de sitios E-commerce",
    image: "/assets/images/blog/Portfolio-EcommerceSites.jpg",
    date: "Sitios E-commerce desarrollados sobre WooCommerce",
    link: "/Ecommerce",
    icon: "ti-mobile",
  },
  {
    title: "Web Scrapping y análisis de precios",
    image: "/assets/images/blog/Portfolio-ScrappingWeb.jpg",
    date: "Scrapping/Monitoreo de precios competencia",
    link: "/Scrapping",
    icon: "ti-paint-bucket",
  },
  {
    title: "Reportes online dinámicos",
    image: "/assets/images/blog/Portfolio-ReportesOnline.jpg",
    date: "Reporte/Auditoría de costos y rentabilidad",
    link: "/Reportes",
    icon: "ti-light-bulb",
  },
  {
    title: "Desarrollo Intranet Corporativa",
    image: "/assets/images/blog/Portfolio-Intranet.jpg",
    date: "Plataforma web interna para administración de procesos y gestión",
    link: "/Intranet",
    icon: "ti-light-bulb",
  },
];

function Blog() {
  let sliderSettings = {
    dots: true,
    speed: 500,
    arrows: false,
    infinite: false,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerPadding: 30,
    draggable: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { path } = useRouteMatch();

  return (
    <section className="section section-blog section-blog-1">
      <div className="display-spacing">
        <Container className="container">
          <Headline
            label="Portafolio"
            title="Conoce mi trabajo"
            divider_1={true}
            position="center"
          />
          <Slider className="el-slider el-slider-plr--15" {...sliderSettings}>
            {posts.map((item, index) => (
              <div key={index} className="post-item">
                <Link
                  to={`${path}${item.link}`.replace(/([^:])(\/\/+)/g, "$1/")}
                >
                  <div
                    className="post-image"
                    style={{ backgroundImage: `url(${item.image})` }}
                  ></div>
                </Link>
                <div className="post-content">
                  <div className="el-icon">
                    <span className={`el-icon-icon ${item.icon}`}></span>
                  </div>
                  <div className="post-data">
                    <h4 className="post-title">
                      <Link
                        to={`${path}${item.link}`.replace(
                          /([^:])(\/\/+)/g,
                          "$1/"
                        )}
                      >
                        {item.title}
                      </Link>
                    </h4>
                    <div className="post-date">
                      <i className="ti-time"></i>
                      {item.date}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>
    </section>
  );
}

export default Blog;
