import { getDefaultNormalizer } from "@testing-library/react";
import React from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";
import { useState } from "react";
import { send } from 'emailjs-com';



function Contact() {

    const [contact_success, setContactSuccess] = useState("");

    const [toSend, setToSend] = useState({
        from_name: '',
        from_email: '',
        message: ''
    });
    
    const onSubmit = (e) => {
        e.preventDefault();
        send(
            'service_646oryo',
            'template_l121a6t',
            toSend,
            'user_2ycXXG9QRyHjhw8oSzuM0'
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setContactSuccess("Mensaje enviado correctamente");
            setToSend({ from_name: '', from_email: '', message: ''});
        })
        .catch((err) => {
            console.log('FAILED...', err);
            setContactSuccess("No se pudo enviar el mensaje, por favor intente nuevamente.");
        });
    };
    
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    return (
        <section className="section section-contact section-contact-1">
            <div className="display-spacing">
                <Container>
                    <Row>
                        <Hidden xs sm md>
                            <Col lg={5}>
                                <div className="contact-image">
                                    <div className="image overlay-image" style={{ backgroundImage: `url(/assets/images/contact/contactame.jpg)` }}></div>
                                </div>
                            </Col>
                        </Hidden>
                        <Col lg={7}>
                            <div className="contact-content">
                                <header className="el-heading">
                                    <h2 className="el-icon">
                                        <span className="el-icon-title">CONTÁCTAME</span>
                                    </h2>
                                    <h3>
                                        <span>Contáctate conmigo.</span>
                                    </h3>
                                    <div className="divider divider-1"></div>
                                    <p>Puedes contactarme a través del siguiente formulario de contacto, o a través de cualquiera de mis redes/canales publicados en la barra izquierda de este sitio.</p>
                                </header>
                                <form className="form form-1 pt-15">
                                    <Row className="row">
                                        <Col xs={12} sm={12} md={6}>
                                            <div className="form-item">
                                                <input type="text" id="form-item-name" name='from_name' value={toSend.from_name} onChange={handleChange} />
                                                <label htmlFor="form-item-name">Tu nombre</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <div className="form-item">
                                                <input type="email" id="form-item-email" name='from_email' value={toSend.from_email} onChange={handleChange} />
                                                <label htmlFor="form-item-email">Tu e-mail</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12}>
                                            <div className="form-item">
                                                <textarea id="form-item-message" name='message' value={toSend.message} onChange={handleChange}></textarea>
                                                <label htmlFor="form-item-message">Tu mensaje</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12}>
                                            <div><button onClick={onSubmit} className="button button-md button-primary">Enviar mensaje</button></div>
                                            <div><label>{contact_success}</label></div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default Contact;
