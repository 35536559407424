import React from "react";
import { FaCheck } from "react-icons/fa";
import { Container } from "react-grid-system";
// Components
import GoBack from "../../components/common/GoBack";

function BlogSinglePost() {
    return (
        <section className="section section-single-post section-single-post-1">
            <div className="display-spacing">
                <Container>
                    <div className="max-w-768 div-center">
                        <div className="post">
                            <div className="post-head">
                                <div class="post-info">
                                    <GoBack />
                                    <h1>Web Scrapping y análisis de precios</h1>
                                </div>
                            </div>
                            <div className="post-body">
                                <div className="post-image">
                                    <img src="/assets/images/blog/Portfolio-ScrappingWeb.jpg" alt="Single Post" />
                                </div>
                                <div className="post-content">
                                    <p>Programa desarrollado en Python utilizando Selenium para conectarse vía web browser a diferentes sitios web de supermercados y e-commerce de la competencia, y obtener diariamente los precios de venta en cada sitio.</p>
                                    <p>
                                        <b>Algunas características:</b>
                                    </p>
                                    <ul class="el-list-check">
                                        <li>
                                            <FaCheck className="icon" />
                                            Información es recolectada, procesada, y convertida a su equivalente en productos propios del cliente, para así generar un comparativo del precio actual de venta del cliente, el precio de los supermercados, y el precio de e-commerce de la competencia
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Todos los días se ejecuta el proceso y se genera un archivo excel con esta información que es enviado automáticamente al equipo correspondiente, además de contar con reportes online en Data Studio para la visualización de la información histórica.
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Infraestructura sobre Amazon AWS.
                                        </li>
                                    </ul>
                                    <p>Por motivos de confidencialidad del cliente no puedo compartir enlaces y links a este desarrollo, pero puedes contactarme si necesitas más información.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default BlogSinglePost;
