import React from "react";
import { Container, Row, Col } from "react-grid-system";
// Components
import ProgressBar from "../../components/common/ProgressBar";

const skills = [
  {
    title: "Git & GitHub",
    percent: 100,
  },
  {
    title: "Jenkis & CI/CD",
    percent: 85,
  },
  {
    title: "Docker & Kubernetes",
    percent: 90,
  },
  {
    title: "Ansible & Terraform",
    percent: 70,
  },
  {
    title: "Prometheus & Graphana",
    percent: 90,
  },
  {
    title: "Google Cloud (GCP)",
    percent: 90,
  },
  {
    title: "Linux, Nginx, SSL",
    percent: 100,
  },
  {
    title: "HTML, PHP, JavaScript",
    percent: 100,
  },
  {
    title: "Python & Django",
    percent: 95,
  },
  {
    title: "NodeJS & Express",
    percent: 90,
  },
  {
    title: "React",
    percent: 90,
  },
  {
    title: "Flutter",
    percent: 90,
  },
];
const education = [
  {
    title: "Ingeniero Civil Industrial, mención Tecnologías de la Información",
    subtitle: "Universida Adolfo Ibáñez | Chile",
    description:
      "Ingeniero civil industrial con mención en Tecnologías de la Información.",
    date: "(2003-2009)",
    icon: "ti-crown",
  },
  {
    title: "Magíster en Ingeniería de los Negocios",
    subtitle: "Universidad Adolfo Ibáñez | Chile",
    description:
      "Magister de continuidad obtenido junto con mi título profesional.",
    date: "(2003-2009)",
    icon: "ti-cup",
  },
  {
    title: "MBA - Master Business Administration",
    subtitle: "University of Maryland | USA",
    description: "Programa MBA Core Curriculum cursado de forma online.",
    date: "(2019-2020)",
    icon: "ti-ruler-alt-2",
  },
];
const experience = [
  {
    title: "DevOps",
    subtitle: "",
    description:
      "2+ años de experiencia en CI / CD principalmente en infraestructura cloud, utilizando Git, Github, Jenkins, Docker, Kubernetes, Ansible, Terraform, Graphana, Prometheus, y Zabbix. 10+ años en Linux, Nginx, SSL",
    date: "",
    icon: "ti-target",
  },
  {
    title: "Cloud",
    subtitle: "",
    description:
      "3+ años trabajando con Google Cloud Platform, despué de partir en AWS. Experiencia GCP (Google Cloud Platform) en Compute Engine, Storage, GKE, Cloud SQL, Machine Learning y NLP APIS.",
    date: "",
    icon: "ti-cloud",
  },
  {
    title: "Desarrollo Software",
    subtitle: "",
    description:
      "10+ años con HTML, PHP, JavaScript; +5 años con Pyhton, Django, Django Rest Framework; +3 años con TypeScript, NodeJs, y Express; +1 años con React",
    date: "",
    icon: "ti-view-list-alt",
  },
];

function Resume() {
  return (
    <section className="section section-resume section-resume-1">
      <div className="display-spacing">
        <header className="el-heading el-heading-center">
          <div className="el-icon">
            <h2 className="el-icon-title">
              <span>RESUMEN / CV</span>
            </h2>
          </div>
          <h3>Educación & Habilidades</h3>
          <div className="divider divider-1-reverse divider-1-1"></div>
          <div className="divider divider-1-reverse divider-1-2"></div>
        </header>
        <Container className="mb-spacing">
          <Row>
            <Col md={6}>
              <div className="resume-row">
                <h4 className="resume-title">Educación</h4>
                <ul>
                  {education.map((item, index) => (
                    <li key={index}>
                      <div className="resume-item">
                        <div className="resume-head">
                          <div className="el-icon">
                            <span
                              className={`el-icon-icon ${item.icon}`}
                            ></span>
                          </div>
                          <h5>{item.title}</h5>
                          <h6>{item.subtitle}</h6>
                        </div>
                        <div className="resume-body">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="resume-row">
                <h4 className="resume-title">Habilidades</h4>
                <ul>
                  {experience.map((item, index) => (
                    <li key={index}>
                      <div className="resume-item">
                        <div className="resume-head">
                          <div className="el-icon">
                            <span
                              className={`el-icon-icon ${item.icon}`}
                            ></span>
                          </div>
                          <h5>{item.title}</h5>
                        </div>
                        <div className="resume-body">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            {skills.map((item, index) => (
              <Col key={index} sm={6} xl={4}>
                <div className="skills-item">
                  <ProgressBar label={item.title} percent={item.percent} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Resume;
