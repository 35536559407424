import React from "react";
import { Link } from "react-router-dom";

function error404() {
  return (
    <section className="section section-error-404 overlay-image">
      <div className="display-center">
        <div className="error-content text-center">
          <h1>404!</h1>
          <h3>Página no encontrada</h3>
          <p>La página que estás buscando no fue encontrada.</p>
          <Link to="/home-1/intro">
            <button className="button button-lg button-primary">
              <span className="wave"></span>
              <span className="text">VOLVER AL INICIO</span>
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default error404;
