import React from "react";
import { FaCheck } from "react-icons/fa";
import { Container } from "react-grid-system";
// Components
import GoBack from "../../components/common/GoBack";

function BlogSinglePost() {
    return (
        <section className="section section-single-post section-single-post-1">
            <div className="display-spacing">
                <Container>
                    <div className="max-w-768 div-center">
                        <div className="post">
                            <div className="post-head">
                                <div class="post-info">
                                    <GoBack />
                                    <h1>Desarrollo Intranet Corporativa</h1>
                                </div>
                            </div>
                            <div className="post-body">
                                <div className="post-image">
                                    <img src="/assets/images/blog/Portfolio-Intranet.jpg" alt="Single Post" />
                                </div>
                                <div className="post-content">
                                    <p>Plataforma web montada en amazon AWS, utilizando WordPress y Data Studio para presentar más de 15 reportes diferentes con indicadores de negocio como márgenes, rentabilidad, usuarios, y otros más.</p>
                                    <p>
                                        <b>Algunas características:</b>
                                    </p>
                                    <ul class="el-list-check">
                                        <li>
                                            <FaCheck className="icon" />
                                            Programa desarrollado en Python para la recolección de todos los datos conectándose a 3 bases de datos diferentes (bases de datos App, Sitio Ecommerce, y sistema propio de procesamiento de órdenes) para obtener la información necesaria, analizar los datos, y generar la información de negocios presentada a través de Data Studio conectádo directamente a la base de datos consolidada.
                                        </li>
                                    </ul>
                                    <p>Por motivos de confidencialidad del cliente no puedo compartir enlaces y links a este desarrollo, pero puedes contactarme si necesitas más información.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default BlogSinglePost;
