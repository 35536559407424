import React from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";

function About() {
  return (
    <section className="section section-about section-about-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Hidden xs sm md>
              <Col lg={5}>
                <div className="about-image">
                  <div
                    className="image overlay-image"
                    style={{
                      backgroundImage: `url(/assets/images/about.jpg)`,
                    }}
                  ></div>
                </div>
              </Col>
            </Hidden>
            <Col lg={7}>
              <div className="about-content">
                <header className="el-heading">
                  <h2 className="el-icon">
                    <span className="el-icon-title">SOBRE MÍ</span>
                  </h2>
                  <h3>
                    <span>Hola, soy Sebastián Espinosa.</span>
                  </h3>
                  <div className="divider divider-1"></div>
                  <p>
                    Soy un profesional híbrido con muy buenas habilidades
                    técnicas, blandas, y comerciales. De forma auto didacta he
                    desarrollado habilidades de DevOps y desarrollador FullStack
                    en varias tecnologías.
                  </p>
                </header>
                <Row className="pt-30">
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-ruler-pencil"></span>
                      </div>
                      <div className="content">
                        <h5>CTO</h5>
                        <p>
                          Genero impacto positivo en el negocio a través de
                          tecnología.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-image"></span>
                      </div>
                      <div className="content">
                        <h5>Tech Leader</h5>
                        <p>
                          Habilidades blandas para liderar equipos de alto
                          desempeño.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-brush-alt"></span>
                      </div>
                      <div className="content">
                        <h5>Desarrollo FullStack</h5>
                        <p>Python, Django, React, Typescript, NodeJS, y más.</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-ruler-pencil"></span>
                      </div>
                      <div className="content">
                        <h5>Mobile Apps</h5>
                        <p>
                          Aprendiendo Flutter para sumarme el desarrollo Mobile.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-ruler-pencil"></span>
                      </div>
                      <div className="content">
                        <h5>E-commerce</h5>
                        <p>
                          En todos sus ámbitos, no sólo plataforma de
                          tecnología.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-mobile"></span>
                      </div>
                      <div className="content">
                        <h5>Marketing Online y Analytics</h5>
                        <p>Porque es uno con el mundo e-commerce.</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default About;
