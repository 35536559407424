import React from "react";
import { FaCheck } from "react-icons/fa";
import { Container } from "react-grid-system";
// Components
import GoBack from "../../components/common/GoBack";

function BlogSinglePost() {
    return (
        <section className="section section-single-post section-single-post-1">
            <div className="display-spacing">
                <Container>
                    <div className="max-w-768 div-center">
                        <div className="post">
                            <div className="post-head">
                                <div class="post-info">
                                    <GoBack />
                                    <h1>Reportes online dinámicos</h1>
                                </div>
                            </div>
                            <div className="post-body">
                                <div className="post-image">
                                    <img src="/assets/images/blog/Portfolio-ReportesOnline.jpg" alt="Single Post" />
                                </div>
                                <div className="post-content">
                                    <p>Programa desarrollado en Pythonpara obtenerde diversas fuentesde información (Google Sheetcon histórico de compras, y Base de datos de sistema propio con información de ventas) para analizar, procesar, y calcular indicadores de variación de costos y márgenes de contribución de cada pedido y producto.</p>
                                    <p>
                                        <b>Algunas características:</b>
                                    </p>
                                    <ul class="el-list-check">
                                        <li>
                                            <FaCheck className="icon" />
                                            Informe diario con resultados de algoritmo propio desarrollado para detectar fallas en la información, y/o anomalías o variaciones excesivas en los precios de compra de materia prima.
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Reportería online para acceder a toda la información de negocio relacionada a márgenes de contribución de pedidos, productos, e indicadores de cumplimiento en la entrega de productos solicitados.
                                        </li>
                                    </ul>
                                    <p>Por motivos de confidencialidad del cliente no puedo compartir enlaces y links a este desarrollo, pero puedes contactarme si necesitas más información.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default BlogSinglePost;
