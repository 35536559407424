import React from "react";
import Slider from "react-slick";
import { Container } from "react-grid-system";

const service = [
    {
        title: "APPS MÓVILES",
        subtitle: "Desarrollo de aplicaciones móviles nativas para Android y iOS",
        link: "/service-details",
        icon: "ti-mobile",
    },
    {
        title: "SITIO ECOMMERCE",
        subtitle: "Implementación de tu sitio E-Commerce propio, personalizado y adaptado a tu negocio",
        link: "/service-details",
        icon: "ti-shopping-cart",
    },
    {
        title: "MARKETING DIGITAL",
        subtitle: "Diseño e implementación de estrategias de Marketing Digital",
        link: "/service-details",
        icon: "ti-heart",
    },
    {
        title: "DESARROLLO WEB",
        subtitle: "Tu sitio web, intranet, reportes online, y más desarrollado en diferentes tecnologías",
        link: "/service-details",
        icon: "ti-home",
    },
    {
        title: "DEVOPS",
        subtitle: "Implementación de infraestructua, plataformas, y herramientas DevOps para tu negocio",
        link: "/service-details",
        icon: "ti-server",
    },
    {
        title: "APIs y WEBSERVICES",
        subtitle: "Desarrollo de APIs, WebHooks, y WebServices para integrar tu negocio",
        link: "/service-details",
        icon: "ti-shortcode",
    },
    {
        title: "SCRIPTING & ANÁLISIS DE DATOS",
        subtitle: "Programación de Scripts para obtener, analizar, procesar, y presentar datos para tu negocio.",
        link: "/service-details",
        icon: "ti-target",
    },
];

function Service() {
    let sliderSettings = {
        dots: true,
        speed: 500,
        arrows: false,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    centerPadding: 50,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: 100,
                },
            },
            {
                breakpoint: 570,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="section section-service section-service-5">
            <div className="display-spacing mb-0">
                <Container>
                    <header className="el-heading el-heading-center">
                        <div className="el-icon">
                            <h2 className="el-icon-title">
                                <span>SERVICIOS</span>
                            </h2>
                        </div>
                        <h3>¿Con qué te puedo ayudar?</h3>
                        <div className="divider divider-1-reverse divider-1-1"></div>
                        <div className="divider divider-1-reverse divider-1-2"></div>
                    </header>
                    <Slider className="el-slider el-slider-plr--15 mb-30" {...sliderSettings}>
                        {service.map((item, index) => (
                            <div className="service-wrap" key={index}>
                                <div className="service-item">
                                    <div className="service-head">
                                        <span className={`el-icon-icon ${item.icon}`}></span>
                                    </div>
                                    <div className="service-body">
                                        <h3>{item.title}</h3>
                                        <p>{item.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </Container>
            </div>
        </section>
    );
}

export default Service;
