import React from "react";
import { FaCheck } from "react-icons/fa";
import { Container } from "react-grid-system";
// Components
import GoBack from "../../components/common/GoBack";

function BlogSinglePost() {
    return (
        <section className="section section-single-post section-single-post-1">
            <div className="display-spacing">
                <Container>
                    <div className="max-w-768 div-center">
                        <div className="post">
                            <div className="post-head">
                                <div class="post-info">
                                    <GoBack />
                                    <h1>Plataforma Web de análisis de contenidos para Redes Sociales</h1>
                                </div>
                            </div>
                            <div className="post-body">
                                <div className="post-image">
                                    <img src="/assets/images/blog/Portfolio-SMA.jpg" alt="SMA - Smart Media Analysis" />
                                </div>
                                <div className="post-content">
                                    <p>Programa desarrollado en Python para a través de Scrapping Web (utilizando Selenium) y/o conexiones API, obtener la información pública de contenidos publicados por una cuenta en redes sociales. Actualmente obteniendo información desde Twitter, Facebook, e Instagram.</p>
                                    <p>
                                        <b>Algunos antecedentes:</b>
                                    </p>
                                    <ul class="el-list-check">
                                        <li>
                                            <FaCheck className="icon" />
                                            Información es recolectada, analizada, y procesada para generar indicadores que no entregan las plataformas de redes sociales.
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            El contenido de cada publicación es analizado a través de algoritmos de NPL (Natural Processing Language) y herramientas cloud de
Machine Learing y NPL de Google, para analizar, procesar, y categorizar el contenido de la publicación.
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Toda esta información es accesible para el cliente a través de plataforma web que permite acceder a más de 8 reportes online es-
tandarizados y disponibles para cada canal (red social).
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Desarrollo realizado bajo estrucura de MicroServicios para el consumo de los resultados de información analizanda, implementando diferentes APIs utilizando Django para desarrollar y habilitar las APIs con control de acceso.
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Infraestructura sobre Google Clou.
                                        </li>
                                    </ul>
                                    <p>Puedes visitar y conocer más sobre este desarrollo en su web <a href="http://sma.whatif.cl" target="_blank">sma.whatif.cl</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default BlogSinglePost;
