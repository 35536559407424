import React, { useState } from "react";
import { Link } from "react-router-dom";

const pages = [
    {
        title: "All Demos",
        image: "/assets/images/demo/demos.jpg",
        link: "/",
    },
    {
        title: "Home 1",
        image: "/assets/images/demo/1.jpg",
        link: "/home-1/intro",
    },
    {
        title: "Home 2",
        image: "/assets/images/demo/2.jpg",
        link: "/home-2/intro",
    },
    {
        title: "Home 3",
        image: "/assets/images/demo/3.jpg",
        link: "/home-3/intro",
    },
    {
        title: "Home 4",
        image: "/assets/images/demo/4.jpg",
        link: "/home-4/intro",
    },
    {
        title: "Home 5",
        image: "/assets/images/demo/5.jpg",
        link: "/home-5/intro",
    },
    {
        title: "Home 6",
        image: "/assets/images/demo/6.jpg",
        link: "/home-6/intro",
    },
];

function DemoOptions() {

    return (<div></div>);

}

export default DemoOptions;
