import React from "react";
import { FaCheck } from "react-icons/fa";
import { Container } from "react-grid-system";
// Components
import GoBack from "../../components/common/GoBack";

function BlogSinglePost() {
    return (
        <section className="section section-single-post section-single-post-1">
            <div className="display-spacing">
                <Container>
                    <div className="max-w-768 div-center">
                        <div className="post">
                            <div className="post-head">
                                <div class="post-info">
                                    <GoBack />
                                    <h1>Desarrollo sitios E-Commerce</h1>
                                </div>
                            </div>
                            <div className="post-body">
                                <div className="post-image">
                                    <img src="/assets/images/blog/Portfolio-EcommerceSites.jpg" alt="Single Post" />
                                </div>
                                <div className="post-content">
                                    <p>Más de 5 sitios E-commerce desarrollados por completo sobre WordPress y WooCommerce para Chile y USA.</p>
                                    <p>
                                        <b>Algunas características:</b>
                                    </p>
                                    <ul class="el-list-check">
                                        <li>
                                            <FaCheck className="icon" />
                                            ServidorweboptimizadoennubedegoogleutilizandoLinux,Nginx,SSL,Varnish,yPHP,todosoptimizados. Infraestructura de 3 capas (webserver, almacenamiento, base de datos) independientes y en alta disponibilidad, además de auto escalabilidad utilizando GKE, y monitoreo proactivo a través de Monit y Zabbix
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            SitiowebdesarrolladossobreWordPress, WooCommerce, y desarrollo de Webservices y Webhooks utilizando APIs de WooCommerce y proveedores para integración. Diseño gráfico realizado
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Sitio optimizados para correcto tracking en Google Analytics y optimización SEO.
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Sitios integrados con Google Search Console, Facebook y Instragram Shopping, Google Shopping, SendGrid (SMTP), y plataformas de Pago (Flow, Transbank, MercadoPago).
                                        </li>
                                    </ul>
                                    <p>Te invito a visitar algunos de estos sitios para conocer más acerca de mi trabajo para cada uno de ellos.</p>
                                    <p>Super Food Clan - <a href="http://www.superfoodclan.cl" target="_blank">www.superfoodclan.cl</a><br />
                                    Caffesso USA - <a href="http://www.caffessousa.com" target="_blank">www.superfoodclan.cl</a><br />
                                    Huaso Choppers - <a href="http://www.huasochoppers.com" target="_blank">www.huasochoppers.com</a><br />
                                    Comercializado Ambiente - <a href="http://www.cambiente.cl" target="_blank">www.cambiente.cl</a><br />
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default BlogSinglePost;
